import {
  ArrowLeftOnRectangleIcon,
  ChatBubbleLeftIcon,
  Cog6ToothIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/20/solid";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { parseWithZod } from "@conform-to/zod";
import { FormItem, FormLabel, FormError } from "~/components/ui/form";
import { Dialog, DialogFooter, ScrollableDialogContent, DialogHeader, ScrollContent } from "~/components/ui/dialog";
import { Form, Link, useFetcher } from "@remix-run/react";
import { cn } from "~/lib/utils";
import { useState, useEffect } from "react";
import { type action as issueReportAction, formSchema as issueReportFormSchema } from "../routes/issue-report.ts";
import { Button } from "./ui/button.tsx";
import { getFormProps, getInputProps, useForm } from "@conform-to/react";
import Spinner from "./Spinner.tsx";
import { Textarea } from "./ui/textarea.tsx";
import { Input } from "./ui/input.tsx";

export default function UserMenu({ email, small }: { email: string; small?: boolean }) {
  const [issueReportOpen, setIssueReportOpen] = useState(false);
  const issueReportFetcher = useFetcher<typeof issueReportAction>();

  const [form, { text }] = useForm({
    lastResult: issueReportFetcher.data,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: issueReportFormSchema });
    },
  });

  useEffect(() => {
    if (issueReportFetcher.state === "idle" && issueReportFetcher.data?.status === "success") setIssueReportOpen(false);
  }, [issueReportFetcher]);

  const loading = issueReportFetcher.state === "submitting";

  return (
    <>
      <Dialog open={issueReportOpen} onOpenChange={setIssueReportOpen}>
        <ScrollableDialogContent className="sm:max-w-md">
          <ScrollContent>
            <DialogHeader>Problem melden</DialogHeader>
            <issueReportFetcher.Form method="post" {...getFormProps(form)} action={`/issue-report`}>
              <FormItem className="my-2 w-full">
                <FormLabel>Ihre E-Mail</FormLabel>
                <Input disabled readOnly className="w-full opacity-60" defaultValue={email} />
              </FormItem>
              <FormItem errors={text.errors} className="my-2 w-full">
                <FormLabel>Beschreiben Sie das Problem</FormLabel>
                <Textarea className="w-full" {...getInputProps(text, { type: "text" })} />
                <FormError />
              </FormItem>
              <DialogFooter>
                <Button type="button" variant="outline" onClick={() => setIssueReportOpen(false)} disabled={loading}>
                  Schließen
                </Button>
                <Button type="submit" disabled={loading}>
                  {loading && <Spinner className="mr-2" />}
                  Absenden
                </Button>
              </DialogFooter>
            </issueReportFetcher.Form>
          </ScrollContent>
        </ScrollableDialogContent>
      </Dialog>

      <DropdownMenu>
        <DropdownMenuTrigger
          aria-label="User Menü"
          className={cn(
            "flex items-center justify-end gap-3 rounded-lg p-1 px-4 hover:bg-slate-200 radix-state-open:bg-slate-700 radix-state-open:text-white  md:max-w-xs",
            !small && "md:min-w-[200px]",
          )}
        >
          {!small && <div className="hidden flex-1 truncate md:block">{email}</div>}
          <UserCircleIcon className="h-8 w-8" />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="md:w-radix-dropdown-menu-trigger-width min-w-[200px]">
          <DropdownMenuLabel>{email}</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem asChild>
            <Link to="/settings">
              <Cog6ToothIcon className="mr-2 w-4" />
              <span>Einstellungen</span>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setIssueReportOpen(true)}>
            <ChatBubbleLeftIcon className="mr-2 w-4" />
            <span>Problem melden</span>
          </DropdownMenuItem>
          <Form action="/logout" method="POST">
            <DropdownMenuItem asChild>
              <button className="flex h-full w-full items-center">
                <ArrowLeftOnRectangleIcon className="mr-2 w-4" />
                <span>Ausloggen</span>
              </button>
            </DropdownMenuItem>
          </Form>
          <DropdownMenuSeparator />
          <DropdownMenuItem asChild>
            <a
              href="https://training.leiter-check.bghw.de/"
              target="_blank"
              rel="noreferrer"
              className="text-primary-900 block"
            >
              <ArrowTopRightOnSquareIcon className="mr-2 w-4" />
              Leiter-Training
            </a>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link to="/privacy-policy" className="text-primary-900 block">
              Datenschutz
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link to="/imprint" className="text-primary-900 block">
              Impressum
            </Link>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
